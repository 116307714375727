import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Img from 'gatsby-image';

const MAX_IMAGES = 6;
const TOP_LEFT = 'TOP_LEFT';
const TOP_RIGHT = 'TOP_RIGHT';
const BOTTOM_LEFT = 'BOTTOM_LEFT';
const BOTTOM_RIGHT = 'BOTTOM_RIGHT';

import Video from '../../components/video';
import { Conditional } from '../../components/utils';

import styles from './grid.module.scss';

const Grid = ({ title, text, images, template }) => {
	const isOdd = images.length % 2 !== 0;
	let templateClassName;

	switch (template) {
		case TOP_LEFT:
			templateClassName = styles.itemTopLeft;
			break;
		case TOP_RIGHT:
			templateClassName = styles.itemTopRight;
			break;
		case BOTTOM_LEFT:
			templateClassName = styles.itemBottomLeft;
			break;
		case BOTTOM_RIGHT:
			templateClassName = styles.itemBottomRight;
			break;
		default:
	}

	const items = images
		.slice(0, MAX_IMAGES)
		.map(({ image, video, label, link }, index) => {
			const className = [
				styles.item,
				(isOdd && styles.itemIsOdd) || '',
				(link && styles.itemHasLink) || '',
				(image && styles.itemHasImage) || '',
				(index === 0 && templateClassName) || ''
			].join(' ');

			return (
				<Conditional
					key={index}
					condition={link}
					wrapper={children => (
						<Link to={link} className={className}>
							{children}
						</Link>
					)}
					fallbackWrapper={children => <div className={className}>{children}</div>}>
					<Fragment>
						<div className={styles.label}>{label}</div>
						{/* {image && (
						<div className={styles.image}>
							<Img fluid={image.childImageSharp.fluid} />
						</div>
					)} */}
						{(video && (
							<div className={styles.video}>
								<Video src={video.publicURL} autoplay={true} />
							</div>
						)) ||
							(image && (
								<div className={styles.image}>
									<Img fluid={image.childImageSharp.fluid} />
								</div>
							))}
					</Fragment>
				</Conditional>
			);
		});

	return (
		<div className={styles.wrapper}>
			<h2 className={styles.header}>{title}</h2>
			<div className={styles.grid}>{items}</div>
			{text && <div className={styles.text}>{text}</div>}
		</div>
	);
};

Grid.defaultProps = {
	template: TOP_LEFT,
	title: null,
	text: null,
	images: []
};

Grid.propTypes = {
	template: PropTypes.oneOf([TOP_LEFT, TOP_RIGHT, BOTTOM_LEFT, BOTTOM_RIGHT]),
	title: PropTypes.string.isRequired,
	text: PropTypes.string,
	images: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string.isRequired,
			image: PropTypes.shape({
				childImageSharp: PropTypes.shape({
					fluid: PropTypes.object.isRequired
				})
			}),
			link: PropTypes.string
		})
	).isRequired
};

export default Grid;
