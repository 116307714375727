import React from 'react';
import PropTypes from 'prop-types';

import styles from './youtube.module.scss';

const BASE_URL = 'https://www.youtube-nocookie.com/embed';

const YouTube = ({ id, text, title }) => {
	const url = `${BASE_URL}/${id}?color=white`;

	return (
		<div role="presentation" className={styles.wrapper}>
			<iframe
				src={url}
				title={title}
				className={styles.iframe}
				allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
				frameBorder="0"
				webkitallowfullscreen="true"
				mozallowfullscreen="true"
				allowFullScreen
			/>
		</div>
	);
};

YouTube.defaultProps = {
	id: null
};

YouTube.propTypes = {
	id: PropTypes.string.isRequired
};

export default YouTube;
