import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { useMediaQuery } from 'react-responsive';

import { scrollTo } from '../../utils/dom';
import Video from '../../components/video';
import { XS, SM, MD } from '../../config/breakpoints';
import { ArrowDownIcon } from '../../components/icons';

import styles from './teaser.module.scss';

const Teaser = ({ title, image, video }) => {
	const onClick = evt => {
		if (typeof document === 'undefined') {
			return;
		}

		const {
			currentTarget: { href = '' }
		} = evt;

		const regex = /(#[\w-]+)/;
		const [selector] = href.match(regex) || [];
		const target = document.querySelector(selector);

		evt.preventDefault();
		scrollTo(target);
	};

	// rehydrate media queries on mounting because of SSR!
	const [isClient, setIsClient] = useState(false);
	const isMinXS = useMediaQuery({ minWidth: XS });
	const isMinSM = useMediaQuery({ minWidth: SM });
	const isMinMD = useMediaQuery({ minWidth: MD });

	useEffect(() => {
		setIsClient(true);
	}, []);

	return (
		<div className={styles.wrapper}>
			<div className={styles.container}>
				<div className={styles.header}>
					<h1 className={styles.title}>{title}</h1>
				</div>
			</div>

			<a href="#body" onClick={onClick} className={styles.scrollLink}>
				<ArrowDownIcon className={styles.scrollIcon} />
			</a>
			{(video && (
				<div className={styles.videoWrapper}>
					<div className={styles.videoContainer}>
						<Video autoplay={true} src={video.publicURL} poster={image.publicURL} />
					</div>
				</div>
			)) ||
				(isMinMD || !isClient ? (
					<Img className={styles.image} fluid={image.childImageSharp.fluid_large} />
				) : (
					<Img
						className={styles.image}
						fixed={
							isMinSM
								? image.childImageSharp.fixed_large
								: isMinXS
								? image.childImageSharp.fixed_medium
								: image.childImageSharp.fixed_small
						}
					/>
				))}
		</div>
	);
};

Teaser.defaultProps = {
	title: null,
	image: null,
	video: null
};

Teaser.propTypes = {
	title: PropTypes.string.isRequired,
	image: PropTypes.shape({
		childImageSharp: PropTypes.shape({
			fixed_small: PropTypes.object.isRequired,
			fixed_medium: PropTypes.object.isRequired,
			fixed_large: PropTypes.object.isRequired,
			fluid_large: PropTypes.object.isRequired
		})
	}).isRequired,
	video: PropTypes.shape({
		publicURL: PropTypes.string
	})
};

export default Teaser;
