import React from 'react';
import { graphql } from 'gatsby';

import { Page } from '../components/layout';
import YouTube from '../components/youtube';

import Grid from './index/grid';
import Teaser from './index/teaser';
import Partners from './shared/partners';
import Customers from './shared/customers';

import styles from './index.module.scss';

export const Index = ({
  data: {
    markdownRemark: {
      frontmatter: { teaser, grids, video },
      fields: { slug },
      html
    }
  }
}) => (
  <Page
    slug={slug}
    body={html}
    showTitle={false}
    extraFooter={
      video ? (
        <div className={styles.extraFooter}>
          <h3 className={styles.extraFooterTitle}>{video.title}</h3>
          <YouTube id={video.youtube_id} />
        </div>
      ) : null
    }
    teaser={<Teaser title={teaser.title} image={teaser.image} video={teaser.video} />}>
    {Array.isArray(grids) &&
      grids.map(({ title, text, images, template }, index) => (
        <div key={index} className={styles.grid}>
          <Grid text={text} title={title} images={images} template={template} />
        </div>
      ))}

    <div className={styles.slider}>
      <Customers />
    </div>

    <div className={styles.slider}>
      <Partners />
    </div>
  </Page>
);

export const indexQuery = graphql`
  query templatesIndexQuery($remarkId: String!) {
    markdownRemark(id: { eq: $remarkId }) {
      frontmatter {
        teaser {
          title
          image {
            childImageSharp {
              fixed_small: fixed(width: 480, height: 480, cropFocus: CENTER) {
                ...GatsbyImageSharpFixed_withWebp
              }
              fixed_medium: fixed(width: 768, height: 768, cropFocus: CENTER) {
                ...GatsbyImageSharpFixed_withWebp
              }
              fixed_large: fixed(width: 1340, height: 893, cropFocus: CENTER) {
                ...GatsbyImageSharpFixed_withWebp
              }
              fluid_large: fluid(maxWidth: 1650) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          video {
            publicURL
          }
        }
        grids {
          title
          text
          images {
            image {
              childImageSharp {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            video {
              publicURL
            }
            label
            link
          }
          template
        }
        video {
          title
          youtube_id
        }
      }
      fields {
        slug
      }
      html
    }
  }
`;

export default Index;
